import http from '@/utils/http/index'

/**
 * 角色管理 角色列表
 * @param {*} params
 * @returns
 */
export const getRolePages = (params) => {
  return http({
    showLoading: true,
    method: 'get',
    url: 'api/authcenter/base/role/wsf/page',
    params
  })
}
/**
 * 角色管理 角色新增
 * @param {*} params
 * @returns
 */
export const createRole = (data) => {
  return http({
    showLoading: true,
    method: 'post',
    url: 'api/authcenter/base/role',
    data
  })
}
/**
 * 角色管理 角色编辑
 * @param {*} params
 * @returns
 */
export const updateRole = (roleId, data) => {
  return http({
    showLoading: true,
    method: 'put',
    url: `api/authcenter/base/role/${roleId}`,
    data
  })
}
/**
 * 获取对应用户的所有角色
 */
export const getRolesByUser = (params) => {
  return http({
    showLoading: true,
    method: 'get',
    url: 'api/authcenter/base/role/wsf/all',
    params
  })
}
/**
 * 获取自定义 配置项
 */

export const getOrgOptions = (params) => {
  return http({
    showLoading: true,
    method: 'get',
    url: 'api/authcenter/org/tenant/licensable/list',
    params
  })
}

/**
 * 根据机构获取用户数据
 * @param {*} params
 * @returns
 */
export const gteCrmUserList = (params) => {
  return http({
    showLoading: true,
    method: 'get',
    url: '/api/authcenter/org/account/page',
    params
  })
}

/**
 * 供应商获取机构名称配置
 */

export const getOrgNameConfigSupplier = (params) => {
  return http({
    method: 'get',
    url: '/api/company/supplier/drop',
    params
  })
}

/**
 * 资金方获取机构名称配置
 */

export const getOrgNameConfigByBank = (params) => {
  return http({
    method: 'get',
    url: '/api/capital/side/list',
    params
  })
}
/**
 *  获取 用户所属部门名称
 */
export const getDepartmentName = (params) => {
  return http({
    method: 'get',
    url: '/api/authcenter/org/user/top',
    params
  })
}
/**
 * 判断角色是否存在
 */
export const checkRoleExist = (params) => {
  return http({
    method: 'get',
    hideLoading: true,
    url: '/api/authcenter/base/role/exist',
    params
  })
}
/**
 * 删除角色
 */
export const delRole = (data) => {
  return http({
    showLoading: true,
    method: 'put',
    url: '/api/authcenter/base/role/delstatus',
    data
  })
}
/**
 * 根据角色Id获取已分配用户
 */
export const getUserListByRoleId = (roleId, params) => {
  return http({
    method: 'get',
    url: `/api/authcenter/base/role/users/${roleId}`,
    params
  })
}
/**
 * 根据角色Id、租户Id获取可分配用户
 */
export const getTenantUsersExclude = (params) => {
  return http({
    method: 'get',
    url: '/api/authcenter/base/tenant/user/users/wsf/exclude',
    params
  })
}
/**
 * 角色分配用户
 */
export const updateUsersTRoles = (data) => {
  return http({
    method: 'post',
    url: '/api/authcenter/base/role/add/users',
    data
  })
}
/**
 * 授权分页查询
 */
export const getresourceByPage = (params) => {
  return http({
    showLoading: true,
    method: 'get',
    url: 'api/authcenter/base/role/auth/resourceByPage',
    params
  })
}
/**
 * 授权应用
 */
export const roleAuth = (data) => {
  return http({
    method: 'post',
    url: '/api/authcenter/base/role/auth/roleAuth',
    data,
    showLoading: true
  })
}
/**
 * 授权应用树形结构
 */
export const getTreeResource = (params) => {
  return http({
    method: 'get',
    url: '/api/authcenter/base/role/auth/resource',
    params
  })
}
/**
 * 授权保存
 */
export const saveAuthBatch = (data) => {
  return http({
    showLoading: true,
    method: 'post',
    url: '/api/authcenter/base/role/auth/batch',
    data
  })
}

export const getKeyInfo = () => {
  return http({
    url: '/api/gateway/encryptKey'
  })
}
