import { HTTP_LOG_FLAG } from '@/config/constant'
import store from '@/store/index'
import qs from 'qs'
import { Message } from 'element-ui'
import requestInterceptor from './http-interceptor-request'
import responseInterceptor from './http-interceptor-response'
import axios from 'axios'
import { hideLoading } from './http-loading'
import { ref } from 'vue'
export {
  cancelAllPending,
  handlePushRequest,
  delPendingRequest
} from './http-cancel'
/**
 * 获取菜单的menuId
 * @returns menuId
 */
export const getMenuIdByUrl = () => {
  const { pathname } = window.location
  const path = pathname.toLocaleLowerCase()
  const map = store.state.sys.menuIdMap || {}
  return map[path]
}

/**
 * 请求地址的路径
 */
export const getCurrentPath = (url) => {
  if (!url.includes('?')) {
    return url
  }
  const arr = url.split('?')
  const res = arr[0] || url
  return res[0] === '/' ? res : `/${res}`
}

export const responseLog = (response) => {
  if (process.env.NODE_ENV === 'development' && HTTP_LOG_FLAG) {
    const randomColor = `rgba(${Math.round(Math.random() * 255)},${Math.round(
      Math.random() * 255
    )},${Math.round(Math.random() * 255)})`
    console.log(
      '%c┍------------------------------------------------------------------┑',
      `color:${randomColor};`
    )
    console.log('| 请求地址：', response.config.url)
    console.log('| 请求参数：', qs.parse(response.config.data))
    console.log('| 返回数据：', response.data)
    console.log(
      '%c┕------------------------------------------------------------------┙',
      `color:${randomColor};`
    )
  }
}

/**
 * 请求报错
 * @param {*} message
 */
export const errorMessage = (message) => {
  Message({
    message,
    type: 'error',
    duration: 3 * 1000
  })
}

export const httpErrorHandle = (error) => {
  if (axios.isCancel(error)) {
    // reject 状态  防止页面逻辑出现then回调报错
    return Promise.reject(error)
  }
  hideLoading(error.config || {})
  errorMessage(error.message)
  return Promise.reject(error)
}

export const HttpKeyInfo = ref({
  enabled: true,
  key: '654321'
})

export {
  requestInterceptor,
  responseInterceptor
}
