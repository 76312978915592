<template>
  <div id="app">
    <router-view v-if="isInit"/>
  </div>
</template>
<script>
import fackClickOutSide from '@/plugin/fackClickOutSide'
import { getKeyInfo } from '@/api/sys/role'
import { HttpKeyInfo } from '@/utils/http/helper/http-util'
export default {
  mixins: [fackClickOutSide],
  data () {
    return {
      isInit: false
    }
  },
  async created () {
    try {
      const { data } = await getKeyInfo()
      HttpKeyInfo.value = data
      this.isInit = true
    } catch (error) {
      this.isInit = true
    }
  }
}
</script>
