import needsMenuIdUrls from '@/constants/menuId-configs'
import { getHeaders } from '../../auth/index'
import { showLoading } from './http-loading'

import { getCurrentPath, getMenuIdByUrl, handlePushRequest, HttpKeyInfo } from './http-util'
import { encryptParams } from '@szfe/share'

/**
 * 接口是否需要传menuId
 * @param {*} param
 * @returns boolean
 */
const isNeedMenuId = ({ url, method }) => {
  return needsMenuIdUrls.includes(getCurrentPath(url)) && method === 'get'
}

/**
 * 请求头参数设置
 * @param {*} config
 */
const setTokenInfo = (config) => {
  const headers = getHeaders()
  if (Object.keys(headers).length) {
    config.headers = headers
  }
}

/**
 * 请求前拦截全局处理
 */
export default (config) => {
  // 重复请求处理
  handlePushRequest(config)
  // token 设置
  setTokenInfo(config)
  // 设置请求loading效果
  showLoading(config)

  // 请求接口处理传入menuId
  if (isNeedMenuId(config)) {
    const menuId = getMenuIdByUrl()
    const beforeParams = config.params || {}
    beforeParams.menuId = menuId
    config.params = beforeParams
  }
  // get 请求添加时间戳
  config = encryptParams(config, HttpKeyInfo.value.key, HttpKeyInfo.value.enabled)
  return config
}
